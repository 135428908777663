<template>
    <div id="NewContainer">
        <div id="header">
            <div class="header_icons">
                {{ gameState.puntos.value }} puntos
            </div>
            <div class="header_icons">
                <img src="../assets/icono_speaker.svg" alt="Sonido" class="icono">
                <router-link to="/"><img @click="soundButton.play()" src="../assets/icono_home.svg" alt="Inicio" class="icono"></router-link>
            </div>
        </div>

        <div id="resultado">
            <div id="mitad_izquierda">
                <img :src="matacho" id="logo_resultado">
                <div id="nombre_resultado_big">{{ resultado }}</div>
            </div>
            <div id="descripcion_resultado">
                <div id="nombre_resultado">{{ resultado }}</div>
                <div id="parrafo_resultado">{{ feedback }}</div>

                <div id="div_vidas">
                    <div id="vidas">
                        <img v-for="v in gameState.vidas.value" :key="v" src="../assets/icono_luparosa.svg" alt="Inicio" class="lupa">
                    </div>
                    <div id="contador_vidas">Te quedan <span class="pink_text">{{ gameState.vidas.value }}</span> vidas</div>
                </div>

                <div id="continuar" @click="soundButton.play(); next_play()">Continuar <img src="../assets/icono_luparosa.svg" alt="Inicio" class="lupa_inline"> </div>
            </div>
        </div>
        <div/>
    </div>
</template>

<script>
import useJuego from '../store/contenidoJuego'

export default {
  setup () {
    const soundCorrecto = document.querySelector('#soundCorrecto')
    const soundIncorrecto = document.querySelector('#soundIncorrecto')
    var resultado = ''
    var feedback = ''
    var matacho = ''
    const gameState = useJuego()
    const guess = gameState.current_guess.value
    const reality = gameState.current_new.value.tipo
    if ((guess === 1 && reality === 'Verdadera') || (guess === -1 && reality === 'Falsa')) {
      soundCorrecto.play()
      resultado = '¡Correcto!'
      feedback = gameState.current_new.value.feedback_correcto
      gameState.racha.value += 1
      gameState.puntos.value += 8 * gameState.multiplicador.value
      if (reality === 'Verdadera') {
        matacho = require('../assets/positivo_1.svg')
      } else {
        matacho = require('../assets/positivo_2.svg')
      }
    } else {
      soundIncorrecto.play()
      resultado = '¡Incorrecto!'
      feedback = gameState.current_new.value.feedback_incorrecto
      gameState.racha.value = 0
      gameState.multiplicador.value = 1
      gameState.vidas.value -= 1
      if (reality === 'Verdadera') {
        matacho = require('../assets/error_1.svg')
      } else {
        matacho = require('../assets/error_2.svg')
      }
    }

    return {
      gameState: gameState,
      resultado: resultado,
      feedback: feedback,
      matacho: matacho,
      soundButton: document.querySelector('#soundButton')
    }
  },
  methods: {
    next_play: function () {
      if (this.gameState.vidas.value === 0 || this.gameState.noticias_disponibles.value.length === 0) {
        this.$router.push('/submit')
      } else {
        if (this.gameState.racha.value === 5) {
          this.gameState.multiplicador.value *= 2
          this.gameState.racha.value = 0
          this.$router.push('/juego/combo')
        } else {
          this.$router.push('/juego/noticia')
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#NewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

#header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.header_icons {
    display: flex;
    align-items: center;
    color: white;
    font: 25px "Bebas Neue";
}
.icono {
    width: 25px;
    height: auto;
    margin: 0px 4px;
}
.icono:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#resultado {
    border: 2px solid white;
    border-radius: 20px;
    width: 80%;
    max-height: 90%;
    margin: 0px auto 25px;

    display: flex;
    flex-direction: column;
}
#mitad_izquierda {
    height: 30%;
}
#logo_resultado {
    height: 100%;
    padding: 10px;
    object-fit: contain;
    transition-duration: 200ms;
}
#logo_resultado:hover {
    transform: scale(1.1);
}
#descripcion_resultado {
    background-color: #54ceea;
    flex-grow: 1;
    border: 2px solid white;
    border-radius: 20px;
    margin: 0px -2px -2px -2px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
#nombre_resultado {
    background-color: #4684b2;
    color: white;
    font: 36px "Bebas Neue";
    margin: -20px auto 0px;
    padding: 4px 15px 0px;
    border: 2px solid white;
    border-radius: 15px;
}
#nombre_resultado_big {
    display: none;
}
#parrafo_resultado {
    width: 80%;
    font: 12px "Helvetica";
}

#div_vidas {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#contador_vidas {
    font: 24px "Bebas Neue";
    display: flex;
    align-items: center;
}
.pink_text {
    font: 42px "Bebas Neue";
    color: #f176f2;
    margin: 0px 7px;
}
#vidas {
    background-color: #0C1D3E;
    border-radius: 20px;
    padding: 5px;

    display: flex;
    align-content: center;
}
.lupa {
    width: 20px;
    height: auto;
}
#continuar {
    background-color: #0C1D3E;
    border: 2px solid white;
    border-radius: 10px;
    color: white;
    font: 30px "Bebas Neue";
    line-height: 1;
    padding: 4px 10px 0px;
    margin-bottom: 15px;

    display: flex;
    align-items: center;
}
#continuar:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.lupa_inline {
    width: 25px;
    height: auto;
    margin: 0px 0px 4px 6px;
}

@media screen and (min-width: 800px) {
    #resultado {
        border: 2px solid white;
        border-radius: 20px;
        width: 80%;
        max-height: 90%;
        margin: 0px auto 25px;

        display: flex;
        flex-direction: row;
    }
    #logo_resultado {
        width: 80%;
        height: 80%;
        padding: 10px;
        object-fit: contain;
    }
    #mitad_izquierda {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    #descripcion_resultado {
        width: 50%;
        background-color: #54ceea;
        flex-grow: 1;
        border: 2px solid white;
        border-radius: 20px;
        margin: 0px -2px -2px -2px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #nombre_resultado {
        display: none;
    }
    #nombre_resultado_big {
        display: block;
        background-color: #4684b2;
        color: white;
        font: 36px "Bebas Neue";
        margin: -20px auto 0px;
        padding: 4px 15px 0px;
        border: 2px solid white;
        border-radius: 15px;
    }
    #parrafo_resultado {
        width: 80%;
        font: 28px "Helvetica";
        padding: 15px;
    }

    #contador_vidas {
        color: white;
        font: 36px "Bebas Neue";
        display: flex;
        align-items: center;
    }
    .pink_text {
        font: 52px "Bebas Neue";
        color: #f176f2;
        margin: 0px 7px;
    }
    #vidas {
        background-color: #0C1D3E;
        border-radius: 40px;
        padding: 10px;

        display: flex;
        align-content: center;
    }
    .lupa {
        width: 40px;
        height: auto;
    }
    #continuar {
        background-color: #0C1D3E;
        border: 2px solid white;
        border-radius: 10px;
        color: white;
        font: 42px "Bebas Neue";
        line-height: 1;
        padding: 4px 10px 0px;
        margin-bottom: 15px;

        display: flex;
        align-items: center;
    }
    .lupa_inline {
        width: 36px;
        height: auto;
        margin: 0px 0px 4px 6px;
    }
}
</style>
